import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import theme from "../utils/theme.js"
import { ThemeProvider } from "styled-components"
import { Header } from "UI/Header"
import { FooterCTA } from "components/Project/FooterCTA"
import { Footer } from "UI/Footer"
import { NavBar } from "./UI/NavBar"

import { Helmet } from "react-helmet"

const Layout = ({ variant, children, pageContext }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        {/* Inspectlet */}
        <script
          type="text/javascript"
          data-avenue={`activecampaign-${pageContext.uri}`}
        >
          {`
            (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
            vgo('setAccount', '224803723');
            vgo('setTrackByDefault', true);

            vgo('process');

            console.log('activecampaign site tracking: ${pageContext.uri}');


          `}
        </script>
      </Helmet>

      <div className="app-wrapper">
        <NavBar />
        <Header variant={variant} pageContext={pageContext} />
        <main>{children}</main>
        <FooterCTA />
        <Footer />
      </div>
      <script
        type="text/javascript"
        async
        src="//l.getsitecontrol.com/v7nvnk9w.js"
      ></script>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
